import React, { useEffect, useRef, useState } from "react";
import "./css/DupageHome.css";
import ticket from "../../Assets/ticket.png";
import ticket1 from "../../Assets/ticket1.png";
import doorimg from "../../Assets/door.png";
import dupage from "../../Assets/dupage.png";
import { GoDotFill } from "react-icons/go";
import { LuCalendar } from "react-icons/lu";
import {
  MdCalendarToday,
  MdOutlineGroup,
  MdOutlineLocationOn,
} from "react-icons/md";
import { FaRegPauseCircle, FaRegPlayCircle } from "react-icons/fa";
import { IoMdCloseCircle, IoMdCheckmarkCircle } from "react-icons/io";
import { CiCalendar } from "react-icons/ci";
import { Line, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useSelector } from "react-redux";
import { devApi } from "../../utils/constants";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

// Register the components
ChartJS.register(
  LineElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

export default function DupageHome() {
  const token = useSelector((state) => state.user.token);
  const [Qfilter, setQFilter] = useState("1");
  const [salesData, setSalesData] = useState();
  const [ticketTypeSalesData, setTicketTypeSalesData] = useState();
  const [ticketTypeCounts, setTicketTypeCounts] = useState();
  const [labels, setlabels] = useState();
  const [datasets, setDatasets] = useState();
  const navigate = useNavigate();
  const [quantityFiletrLoad, setQuantityFiletrLoad] = useState(false);
  const [salesFiletrLoad, setSalesFiletrLoad] = useState(false);
  const [onDashboard, setOnDashboard] = useState(true);
  const [doorManagersList, setDoorManagersList] = useState();
  const [selectedDm, setSelectedDm] = useState("total");
  const [liveTicketScannedData, setLiveTicketScannedData] = useState();
  const [liveTicketsScanByDm, setLiveTicketScanByDm] = useState();
  const [pastTicketScan, setpastTicketScan] = useState();
  const [pastTicketScanLoader, setPastTicketScanLoader] = useState(false);
  const [totalScanLoader, setTotalScanLoader] = useState(false);
  const [pieChartLoader, setPieChartLoader] = useState(false);

  const [adminEntryClose, setAdminEntryClose] = useState(false);
  const [dmScanLoader, setDmScanLoader] = useState(false);
  const [pastDate, setPastDate] = useState();
  const [loadStatusButton, setLoadStatusButton] = useState(false);
  const [loadEntriesStatusButton, setLoadEntriesStatusButton] = useState(false);
  const [toatalPaperScanLoader, setTotalPaperScanLoader] = useState(false);
  const [liveTicketpaperSacnnedData, setLiveTicketPaperScannedData] =
    useState();
  const [dmPaperScanLoader, setDmPaperScanLoader] = useState(false);
  const [selectedDmPaper, setSelectedDmPaper] = useState("total");
  const [liveTicketsPaperScanByDm, setLiveTicketPaperScanByDm] = useState();
  const { state } = useLocation();
  const [eventId, setEventID] = useState(state?.eventsdata[0].event_id);
  const [currentEvent, setCurrentEvent] = useState(state?.eventsdata[0]);
  const [currentEventDetails, setCurrentEventDetails] = useState();

  const options = {
    maintainAspectRatio: false,
    hover: {
      intersect: true,
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (data) => {
            return `$ ${parseInt(data.raw).toFixed(2)}`;
          },
        },
      },
    },
    responsive: true,
    elements: {
      point: {
        radius: 5,
        hitRadius: 5,
      },
    },

    scaleShowHorizontalLines: false,
    scaleShowVerticalLines: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "In dollars",
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "In days",
        },
        grid: {
          display: false,
        },
      },
    },
  };
  const optionsPie = {
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false, position: "right" },
      tooltip: {
        enabled: true,
        usePointStyle: true,
        callbacks: {
          label: (data) => {
            return `$ ${data.parsed.toFixed(2)}`;
          },
        },
      },
    },
    element: {
      radius: 10,
    },
  };
  //fetchers
  useEffect(() => {
    fetchTicketTypesCount();
  }, [Qfilter]);
  //calling total scan after 1 minute
  // useEffect(() => {
  //   const intervalCall = setInterval(() => {
  //     fetchTotalScanData();
  //     fetchTotalPaperScanData();
  //     if (selectedDmPaper !== "total") fetchPaperScanDataByDM();
  //     if (selectedDm !== "total") fetchScanDataByDM();
  //   }, 60000);
  //   return () => {
  //     // clean up
  //     clearInterval(intervalCall);
  //   };
  // }, []);

  //doorm manager dashboard apis
  useEffect(() => {
    const fetchDoorManagersList = async () => {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-type": "application/json",
          Accept: "application/json",
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      const body = {
        event_id: eventId,
      };

      await axios
        .post(`${devApi}/api/o/dm/doorManagerList`, body, config)
        .then((response) => {
          if (response.data.success) {
            setDoorManagersList(response.data.data.doorManagers);
          }
        })
        .catch((error) => {
          if (error.response.status === 450) {
            toast.error("Session Expired, Please log-in again");
            navigate("/organizerlogin");
          }
        });
    };
    if (!onDashboard) {
      fetchDoorManagersList();
      fetchTotalScanData();
    }
  }, [onDashboard]);

  useEffect(() => {
    selectedDm == "total" ? fetchTotalScanData() : fetchScanDataByDM();
  }, [selectedDm]);
  useEffect(() => {
    selectedDmPaper == "total"
      ? fetchTotalPaperScanData()
      : fetchPaperScanDataByDM();
  }, [selectedDmPaper]);

  useEffect(() => {
    fetchTotalPastScans();
  }, [pastDate]);

  useEffect(() => {
    //org data logic
    const fetchTicketTypeSales = async () => {
      setPieChartLoader(true);
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-type": "application/json",
          Accept: "application/json",
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      const body = {
        event_id: eventId,
      };

      await axios
        .post(`${devApi}/api/o/admin/eventsTicketPiChartdata`, body, config)
        .then((response) => {
          if (response.data.success) {
            let labels = [];
            let datasets = [];
            let data = [];
            let backgroundColor = [];
            let borderColor = [];
            response.data.data.ticketData.map((ticket, index) => {
              labels[index] = ticket.label;
              data[index] = ticket.value;
              switch (index) {
                case 0:
                  backgroundColor[index] = "#D8AE41";
                  borderColor[index] = "#D8AE41";
                  break;
                case 1:
                  backgroundColor[index] = "#FF634C";
                  borderColor[index] = "#FF634C";
                  break;
                case 2:
                  backgroundColor[index] = "#4685FF";
                  borderColor[index] = "#4685FF";
                  break;
                case 3:
                  backgroundColor[index] = "#57C6C2";
                  borderColor[index] = "#57C6C2";
                  break;
                case 4:
                  backgroundColor[index] = "#AD72FF";
                  borderColor[index] = "#AD72FF";
                  break;
                default:
                  break;
              }
              return null;
            });
            datasets.push({
              data: data,
              backgroundColor: backgroundColor,
              borderColor: borderColor,
              cutout: "80%",
              borderRadius: 20,
              offset: 3,
            });

            setTicketTypeSalesData({ labels, datasets });
            setPieChartLoader(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 450) {
            setPieChartLoader(false);
            toast.error("Session Expired, Please log-in again");
            navigate("/organizerlogin");
          }
        });
    };
    const fetchOrgData = async () => {
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-type": "application/json",
          Accept: "application/json",
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      const body = {
        event_id: eventId,
      };
      await axios
        .post(`${devApi}/api/o/admin/organiserEventsDashboard`, body, config)
        .then((response) => {
          if (response.data.success) {
            const body = {
              event_id: eventId,
            };
            setCurrentEventDetails(response.data.data);
            axios
              .post(
                `${devApi}/api/o/admin/showEventsTicketSoldSale`,
                body,
                config
              )
              .then((salesResponse) => {
                if (salesResponse.data.success) {
                  setSalesData(salesResponse.data.data);
                }
              });
          }
        })
        .catch((error) => {
          if (error.response.status === 450) {
            toast.error("Session Expired, Please log-in again");
            navigate("/organizerlogin");
          }
        });
    };
    fetchOrgData();
    fetchTicketSalesData();
    fetchTicketTypesCount();
    fetchTicketTypeSales();
  }, [eventId]);

  const fetchTicketSalesData = async (filter) => {
    setSalesFiletrLoad(true);
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const body = {
      event_id: eventId,
      filter: filter,
    };

    await axios
      .post(`${devApi}/api/o/admin/showTicketSaleDateWise`, body, config)
      .then((response) => {
        if (response.data.success) {
          let lables = [];
          let datasets = [];
          let data = [];
          response.data.data.ticketTypeData[0].map((ticket, index) => {
            lables[index] = ticket.date;
            data[index] = ticket.value;
            return null;
          });
          datasets.push({
            data: data,
            tension: 0.5,
            borderColor: "#024DDF",
            pointHoverRadius: 5,
            pointHoverBorderWidth: 7,
          });
          setlabels(lables);
          setDatasets(datasets);
          setSalesFiletrLoad(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/organizerlogin");
        }
      });
  };
  const fetchTicketTypesCount = async () => {
    setQuantityFiletrLoad(true);
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const body = {
      event_id: eventId,
      filter: Qfilter,
    };

    await axios
      .post(`${devApi}/api/o/admin/showEventsTicketsTypeCount`, body, config)
      .then((response) => {
        if (response.data.success) {
          let ticketCounts = { total_sold: response.data.data.total_sold };
          response.data.data.ticketTypeData.map((ticket, index) => {
            ticketCounts[index] = { value: ticket.value, name: ticket.name };
            return null;
          });
          setTicketTypeCounts(ticketCounts);
          setQuantityFiletrLoad(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/organizerlogin");
        }
      });
  };

  const handleTicketQuantityFilter = (event) => {
    setQFilter(event.target.value);
  };
  const handleTicketSalesFilter = (event) => {
    fetchTicketSalesData(event.target.value);
  };
  const handleDoorManagerChange = (event) => {
    let user =
      event.target.value == "total"
        ? "total"
        : doorManagersList.find((dm) => dm.id == event.target.value);
    setSelectedDm(user);
  };
  const handleDoorManagerPaperChange = (event) => {
    let user =
      event.target.value == "total"
        ? "total"
        : doorManagersList.find((dm) => dm.id == event.target.value);
    setSelectedDmPaper(user);
  };

  const fetchScanDataByDM = async () => {
    setDmScanLoader(true);
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const body = {
      event_id: eventId,
      door_managers_id: selectedDm.id,
    };

    await axios
      .post(`${devApi}/api/o/dm/doorManagerOnlineScan`, body, config)
      .then((response) => {
        if (response.data.success) {
          let liveScanned = {
            total_scanned: response.data.data.total_scanned,
          };
          response.data.data.ticket_data.map((ticket, index) => {
            liveScanned[index] = ticket.value;
            return null;
          });
          setLiveTicketScanByDm(liveScanned);
          setDmScanLoader(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/organizerlogin");
        }
        setDmScanLoader(false);
      });
  };
  const fetchTotalScanData = async () => {
    setTotalScanLoader(true);
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const body = {
      event_id: eventId,
    };

    await axios
      .post(`${devApi}/api/o/dm/totalLiveOnlineScan`, body, config)
      .then((response) => {
        if (response.data.success) {
          response.data.data.admin_entry_closed == 1
            ? setAdminEntryClose(true)
            : setAdminEntryClose(false);
          let liveScanned = {
            total_scanned: response.data.data.total_scanned,
          };
          response.data.data.ticket_data.map((ticket, index) => {
            liveScanned[index] = ticket.value;
            return null;
          });
          setTotalScanLoader(false);
          setLiveTicketScannedData(liveScanned);
          setLoadEntriesStatusButton(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/organizerlogin");
        }
        setTotalScanLoader(false);
      });
  };
  const fetchTotalPaperScanData = async () => {
    setTotalPaperScanLoader(true);
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const body = {
      event_id: eventId,
    };

    await axios
      .post(`${devApi}/api/o/dm/totalLiveOfflineScan`, body, config)
      .then((response) => {
        if (response.data.success) {
          let liveScanned = {
            total_scanned: response.data.data.total_scanned,
          };
          response.data.data.ticket_data.map((ticket, index) => {
            liveScanned[index] = ticket.value;
            return null;
          });
          setTotalPaperScanLoader(false);
          setLiveTicketPaperScannedData(liveScanned);
        }
      })
      .catch((error) => {});
  };
  const fetchPaperScanDataByDM = async () => {
    setDmPaperScanLoader(true);
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const body = {
      event_id: eventId,
      door_managers_id: selectedDmPaper.id,
    };

    await axios
      .post(`${devApi}/api/o/dm/doorManagerOfflineScan`, body, config)
      .then((response) => {
        if (response.data.success) {
          let liveScanned = {
            total_scanned: response.data.data.total_scanned,
          };
          response.data.data.ticket_data.map((ticket, index) => {
            liveScanned[index] = ticket.value;
            return null;
          });
          setLiveTicketPaperScanByDm(liveScanned);
          setDmPaperScanLoader(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/organizerlogin");
        }
        setDmPaperScanLoader(false);
      });
  };
  const fetchTotalPastScans = async () => {
    setPastTicketScanLoader(true);
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const body = {
      event_id: eventId,
      filter: pastDate,
    };

    await axios
      .post(`${devApi}/api/o/dm/showtotalTicketTypeScan`, body, config)
      .then((response) => {
        if (response.data.success) {
          let liveScanned = {
            total_scan: response.data.data.total_scan,
          };
          response.data.data.ticketTypeData.map((ticket, index) => {
            liveScanned[index] = ticket.value;
            return null;
          });
          setpastTicketScan(liveScanned);
          setPastTicketScanLoader(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/organizerlogin");
        }
        setPastTicketScanLoader(false);
      });
  };

  const handleToggle = () => {
    setOnDashboard((prev) => !prev);
  };
  const handlePastDateChange = (event) => {
    setPastDate(event.target.value);
  };

  const changeDmStatus = async (logout) => {
    setLoadStatusButton(true);
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const body = {
      event_id: eventId,
      status: logout,
      door_managers_id: selectedDm.id,
    };

    await axios
      .post(`${devApi}/api/o/dm/dmDisableEnable`, body, config)
      .then((response) => {
        if (response.data.success) {
          setLoadStatusButton(false);
          setSelectedDm({ ...selectedDm, logout: logout });
        }
      })
      .catch((error) => {
        setLoadStatusButton(false);
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/organizerlogin");
        }
      });
  };
  const handleAllEntryStatus = async (event) => {
    let status = event.target.innerText == "Pause all entries" ? 1 : 0;
    setLoadEntriesStatusButton(true);
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const body = {
      event_id: eventId,
      status: status,
    };

    await axios
      .post(`${devApi}/api/o/dm/dmEntryCloseOpen`, body, config)
      .then((response) => {
        if (response.data.success) {
          fetchTotalScanData();
        }
      })
      .catch((error) => {
        setLoadEntriesStatusButton(false);
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/organizerlogin");
        }
      });
  };
  const handleDmStatus = async (event) => {
    event.target.innerText == "Deactivate"
      ? changeDmStatus(1)
      : changeDmStatus(0);
  };
  const handleCheckbox = (index, event) => {
    console.log("index", index, event);
    setCurrentEvent(event);
    setEventID(event.event_id);
  };
  if (!salesData) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
  return (
    <div style={{ background: "#E9ECF0" }}>
      <div className="d-flex flex-row justify-content-center align-items-center">
        <div className="btn-group mt-4">
          <button
            type="button"
            className={
              onDashboard
                ? " header-button-dash button-clicked"
                : "header-button-dash"
            }
          >
            Dashboard
          </button>

          <button type="button" className={"header-button-door "} disabled>
            Door Management
          </button>
        </div>
      </div>
      {onDashboard ? (
        <div>
          <div className="container-fluid py-3">
            <div className="d-flex flex-sm-row flex-column page-box justify-content-between">
              <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt-3 padding-box white-box">
                <h6
                  style={{
                    color: "#024DDF",
                    fontSize: "17px",
                    fontWeight: "600",
                  }}
                  className="text-center mt-2"
                >
                  Event List
                </h6>
                <div
                  style={{
                    background: "white",
                    borderRadius: "10px",
                    // height: "fit-content",
                    padding: "0px 6px",
                  }}
                >
                  {state.eventsdata.map((event, index) => {
                    return (
                      <div className="d-flex flex-row justify-content-center align-items-center">
                        <div>
                          <img
                            src={event.event_image_fullpath}
                            alt=""
                            style={{
                              width: "160px",
                              height: "90px",
                              borderRadius: "10px",
                            }}
                          ></img>
                        </div>
                        <div>
                          <div className="fs-6 fw-semibold mt-2 ms-2">
                            {event.title}
                          </div>
                          <div className="banner-location d-flex flex-row ms-2">
                            <MdCalendarToday
                              style={{
                                width: "17px",
                                height: "17px",
                                color: "#024DDF",
                              }}
                            />{" "}
                            <h5 className="banner-location ms-2">
                              {event.startday}{" "}
                            </h5>
                          </div>
                          <div className="banner-location d-flex flex-row ms-1">
                            <MdOutlineLocationOn
                              style={{
                                width: "24px",
                                height: "24px",
                                color: "#024DDF",
                              }}
                            />{" "}
                            <h5
                              className="banner-location ms-1"
                              style={{ maxWidth: "60%" }}
                            >
                              {event.venue_name},{" "}
                              {event.location_address_line_1},{" "}
                              {event.location_address_line_2},{" "}
                              {event.location_post_code}
                            </h5>
                          </div>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={event.event_id == currentEvent.event_id}
                            onChange={() => handleCheckbox(index, event)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-12  ticket-col-box mt-3 ">
                <div className="d-flex justify-content-between">
                  <div className="tickets-count">
                    <img src={ticket} alt="" className="img-fluid" />
                    <div className="ticket-count-data">
                      <h6>{salesData.total_quantity} Tickets</h6>
                      <p>Total Tickets Sold</p>
                    </div>
                  </div>
                  <div className="tickets-count">
                    <img src={ticket1} alt="" className="img-fluid" />
                    <div className="ticket-count-data">
                      <h6>{parseInt(salesData.total_revenue).toFixed(2)}</h6>
                      <p>Total Ticket Sales</p>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    background: "white",
                    borderRadius: "10px",
                    margin: "16px 2px",
                    height: ticketTypeSalesData.length ? "76%" : "72%",
                  }}
                >
                  {pieChartLoader ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ width: "100%", height: "250px" }}
                    >
                      <div className="loader-box">
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12 position-relative">
                      <h6
                        className="mt-3 revenue-head"
                        style={{ marginBottom: 0 }}
                      >
                        Sales by ticket type
                      </h6>

                      <div className="d-flex flex-row justify-content-around">
                        <div className="justify-items-center align-items-center col-5">
                          <Doughnut
                            data={ticketTypeSalesData}
                            options={optionsPie}
                            className="pb-1 mt-3"
                          ></Doughnut>
                        </div>
                        <div className="col-4 d-flex justify-content-center align-items-center flex-column ">
                          {ticketTypeSalesData.labels.map(
                            (lableData, index) => {
                              return (
                                <>
                                  {" "}
                                  <div
                                    className="ticket-types"
                                    style={{
                                      background:
                                        ticketTypeSalesData.datasets[0]
                                          .backgroundColor[index],
                                    }}
                                  ></div>
                                  <p className="ticket-para">{lableData}</p>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt-3 padding-box white-box">
                <div
                  style={{
                    background: "white",
                    borderRadius: "10px",
                    height: "fit-content",
                    padding: "0px 12px",
                  }}
                >
                  <div className="align-items-center justify-content-center">
                    {" "}
                    <img
                      src={currentEventDetails?.banners[0]?.banner_img_fullpath}
                      alt=""
                      className="img-fluid mt-3"
                    />
                  </div>
                  <h6
                    style={{
                      color: "#024DDF",
                      fontSize: "17px",
                      fontWeight: "600",
                    }}
                    className="text-center mt-2"
                  >
                    {currentEventDetails?.event.title}
                  </h6>

                  <h6 style={{ fontSize: "16px", fontWeight: "600" }}>
                    Event Details:
                  </h6>
                  <div className="d-flex- flex-column">
                    <div className="d-flex flex-row justify-content-between">
                      <div className="d-flex align-items-center mb-3">
                        <div className="icon-box me-3">
                          <LuCalendar
                            style={{
                              width: "24px",
                              height: "24px",
                              color: "#121212",
                            }}
                          />
                        </div>
                        <h6 className="info1">
                          {currentEventDetails.event.startday}{" "}
                        </h6>
                        <div className="mx-2">
                          <GoDotFill
                            style={{
                              width: "10px",
                              height: "10px",
                              color: "#121212",
                            }}
                          />
                        </div>
                        <h6 className="info1 me-1">
                          {currentEventDetails.event.starttime}
                        </h6>
                      </div>
                      <div className="d-flex  mb-3">
                        <div className="icon-box-dashboard me-3">
                          <MdOutlineGroup
                            style={{
                              width: "24px",
                              height: "24px",
                              color: "#121212",
                            }}
                          />
                        </div>
                        <h6 className="info1">
                          Organized by{" "}
                          <span style={{ fontWeight: "600" }}>
                            {" "}
                            {currentEventDetails.organizer.name}
                          </span>
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      {" "}
                      <div className="d-flex align-items-center mb-3">
                        <div className="icon-box-dashboard me-3">
                          <MdOutlineLocationOn
                            style={{
                              width: "24px",
                              height: "24px",
                              color: "#121212",
                            }}
                          />
                        </div>
                        <h6 className="info1">
                          {currentEventDetails.event.venue_name},{" "}
                          {currentEventDetails.event.location_address_line_1},{" "}
                          {currentEventDetails.event.location_address_line_2},{" "}
                          {currentEventDetails.event.location_post_code}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid py-2">
            <div className="row page-box justify-content-between">
              <div className="ts-box1 py-2 " style={{ background: "white" }}>
                <div className="d-flex justify-content-between ticket-head-box mt-3">
                  <h4>Tickets Sold</h4>
                  <div>
                    <CiCalendar size={20} className="me-2" />
                    <select
                      style={{ border: "none" }}
                      onChange={handleTicketQuantityFilter}
                    >
                      <option defaultValue value="1">
                        Yesterday
                      </option>
                      <option value="7">Last 7 days</option>
                      <option value="15">Last 15 days</option>
                      <option value="">Total sales</option>
                    </select>
                  </div>
                </div>
                {quantityFiletrLoad ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "100%", height: "250px" }}
                  >
                    <div className="loader-box">
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                ) : (
                  <div className="ticket-sold-box mt-5">
                    <div className="ticket-sold">
                      <h1>{ticketTypeCounts?.total_sold}</h1>
                      <p>Total tickets sold</p>
                    </div>
                    <div className="d-flex flex-row justify-content-sm-start justify-content-center flex-wrap">
                      {_.map(ticketTypeCounts, (ticketType, index) => {
                        return ticketType.name ? (
                          <div
                            className="mega-pass-dash"
                            style={{
                              background:
                                ticketTypeSalesData.datasets[0].backgroundColor[
                                  index
                                ],
                            }}
                          >
                            <h1>{ticketType.value}</h1>
                            <p>{ticketType.name}</p>
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                )}
              </div>
              <div className="ts-box2" style={{ background: "white" }}>
                <div className="d-flex justify-content-between ticket-head-box mt-3">
                  <h4>Tickets Sales</h4>
                  <div>
                    <CiCalendar size={20} className="me-2" />
                    <select
                      style={{ border: "none" }}
                      onChange={handleTicketSalesFilter}
                    >
                      <option defaultValue value="">
                        Total sales
                      </option>
                      <option value="7">Last 7 days</option>
                      <option value="15">Last 15 days</option>
                    </select>
                  </div>
                </div>
                {/* <p className="dollar">In Dollars</p> */}
                {salesFiletrLoad ? (
                  <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "100%", height: "270px" }}
                  >
                    <div className="loader-box">
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "100%", height: "270px" }}
                  >
                    <Line
                      width={"auto"}
                      height={"auto"}
                      data={{ labels, datasets }}
                      options={options}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="container-fluid py-2">
            <div className="d-flex flex-column page-box justify-content-center align-items-center">
              {/*Live data*/}
              <div
                className="ts-box-dm-1 my-2 p-3"
                style={{ background: "white" }}
              >
                <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center mt-4 align-items-sm-start">
                  <div className="d-flex flex-row justify-items-center align-items-center">
                    <div className="blinking-green"></div>
                    <h4
                      className="ms-2 section-header"
                      style={{ marginBottom: "0" }}
                    >
                      Live Scan Data : Online Tickets
                    </h4>
                  </div>
                  {doorManagersList ? (
                    <div className="d-flex flex-sm-row flex-column-reverse justify-content-between align-items-center align-items-sm-start">
                      {selectedDm !== "total" ? (
                        <div className="d-flex flex-row justify-content-between align-items-center mt-3 mt-sm-0">
                          {selectedDm.logout == 0 ? (
                            <button
                              className="btn btn-danger mx-2"
                              onClick={handleDmStatus}
                              disabled={loadStatusButton}
                            >
                              Deactivate
                            </button>
                          ) : (
                            <button
                              className="btn btn-success mx-2"
                              onClick={handleDmStatus}
                              disabled={loadStatusButton}
                            >
                              Activate
                            </button>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="d-flex flex-column align-items-center ">
                        <div className="d-flex justify-content-around select-div mt-2 mt-sm-0 mx-2">
                          <CiCalendar size={20} className="me-2" />
                          <select
                            className="select-width text-center"
                            style={{ border: "none" }}
                            onChange={handleDoorManagerChange}
                          >
                            <option
                              value="total"
                              selected={selectedDm == "total" ? true : false}
                            >
                              Total
                            </option>
                            {doorManagersList.map((doorManager) => {
                              return (
                                <option
                                  key={doorManager.id}
                                  value={doorManager.id}
                                  selected={
                                    selectedDm.id == doorManager.id
                                      ? true
                                      : false
                                  }
                                >
                                  {doorManager.email}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {selectedDm !== "total" ? (
                          <div className="d-flex flex-row justify-content-center align-items-center mt-2">
                            <small>Current Status : </small>
                            {selectedDm.logout == 1 ? (
                              <div className="d-flex flex-row justify-content-center align-items-center">
                                {" "}
                                <small>Deactive </small>{" "}
                                <IoMdCloseCircle size={24} color={"red"} />
                              </div>
                            ) : (
                              <div className="d-flex flex-row justify-content-center align-items-center">
                                {" "}
                                <small>Active </small>{" "}
                                <IoMdCheckmarkCircle
                                  size={24}
                                  color={"green"}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                {!liveTicketScannedData ||
                dmScanLoader ||
                (selectedDm == "total" && totalScanLoader) ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "100%", height: "250px" }}
                  >
                    <div className="loader-box">
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-column flex-sm-row  justify-content-center align-items-center mt-5">
                    <div className="d-flex flex-row justify-content-center flex-wrap">
                      <div className="mega-pass-dash-live">
                        <h1>
                          {selectedDm == "total"
                            ? liveTicketScannedData?.total_scanned
                            : liveTicketsScanByDm
                            ? liveTicketsScanByDm?.total_scanned
                            : ""}
                        </h1>
                        <p>Total scanned</p>
                      </div>
                      <div
                        className="mega-pass-dash"
                        style={{ background: "#D8AE41" }}
                      >
                        <h1>
                          {selectedDm == "total"
                            ? liveTicketScannedData[0]
                            : liveTicketsScanByDm
                            ? liveTicketsScanByDm[0]
                            : ""}
                        </h1>
                        <p>Mega pass</p>
                      </div>
                      <div
                        className="mega-pass-dash"
                        style={{ background: "#FF634C" }}
                      >
                        <h1>
                          {selectedDm == "total"
                            ? liveTicketScannedData[1]
                            : liveTicketsScanByDm
                            ? liveTicketsScanByDm[1]
                            : ""}
                        </h1>
                        <p>Adults</p>
                      </div>
                      <div
                        className="mega-pass-dash"
                        style={{ background: "#024DDF" }}
                      >
                        <h1>
                          {selectedDm == "total"
                            ? liveTicketScannedData[2]
                            : liveTicketsScanByDm
                            ? liveTicketsScanByDm[2]
                            : ""}
                        </h1>
                        <p>Seniors</p>
                      </div>
                      <div
                        className="mega-pass-dash"
                        style={{ background: "#57C6C2" }}
                      >
                        <h1>
                          {selectedDm == "total"
                            ? liveTicketScannedData[3]
                            : liveTicketsScanByDm
                            ? liveTicketsScanByDm[3]
                            : ""}
                        </h1>
                        <p>Children</p>
                        <h3 className="child-desc">(Age 6-12)</h3>
                        {/* <span style={{fontSize:"10px",textAlign:"center"}}>(Age:6-12)</span> */}
                      </div>
                      <div
                        className="mega-pass-dash"
                        style={{ background: "#AD72FF" }}
                      >
                        <h1>
                          {selectedDm == "total"
                            ? liveTicketScannedData[4]
                            : liveTicketsScanByDm
                            ? liveTicketsScanByDm[4]
                            : ""}
                        </h1>
                        <p>Children</p>
                        <h3 className="child-desc">(Age 5 & Under)</h3>
                        {/* <span style={{fontSize:"10px",textAlign:"center"}}>(Age:5 & Under)</span> */}
                      </div>
                    </div>
                  </div>
                )}
                <div className="d-flex flex-column flex-sm-row  justify-content-center align-items-center mt-2">
                  {!adminEntryClose ? (
                    <button
                      type="button"
                      className="d-flex flex-row justify-content-between align-items-center btn btn-outline-primary"
                      onClick={handleAllEntryStatus}
                    >
                      {loadEntriesStatusButton ? (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <>
                          {" "}
                          <h6 className="me-2" style={{ marginBottom: 0 }}>
                            Pause all entries
                          </h6>
                          <FaRegPauseCircle size={24} />
                        </>
                      )}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="d-flex flex-row justify-content-between align-items-center btn btn-outline-primary"
                      onClick={handleAllEntryStatus}
                    >
                      {loadEntriesStatusButton ? (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <>
                          <h6 className="me-2" style={{ marginBottom: 0 }}>
                            Resume entries
                          </h6>
                          <FaRegPlayCircle size={24} />
                        </>
                      )}
                    </button>
                  )}
                </div>
              </div>

              {/*Paper Ticket data*/}
              <div
                className="ts-box-dm-1 my-2 p-3"
                style={{ background: "white" }}
              >
                <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center mt-4 align-items-sm-start">
                  <div className="d-flex flex-row justify-items-center align-items-center">
                    <div className="blinking-green"></div>
                    <h4
                      className="ms-2 section-header"
                      style={{ marginBottom: "0" }}
                    >
                      Live Scan Data : Offline Tickets
                    </h4>
                  </div>
                  {doorManagersList ? (
                    <div className="d-flex flex-sm-row flex-column-reverse justify-content-between align-items-center align-items-sm-start">
                      <div className="d-flex flex-column align-items-center ">
                        <div className="d-flex justify-content-around select-div mt-2 mt-sm-0 mx-2">
                          <CiCalendar size={20} className="me-2" />
                          <select
                            className="select-width text-center"
                            style={{ border: "none" }}
                            onChange={handleDoorManagerPaperChange}
                          >
                            <option
                              value="total"
                              selected={
                                selectedDmPaper == "total" ? true : false
                              }
                            >
                              Total
                            </option>
                            {doorManagersList.map((doorManager) => {
                              return (
                                <option
                                  key={doorManager.id}
                                  value={doorManager.id}
                                  selected={
                                    selectedDmPaper.id == doorManager.id
                                      ? true
                                      : false
                                  }
                                >
                                  {doorManager.email}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                {!liveTicketpaperSacnnedData ||
                dmPaperScanLoader ||
                (selectedDmPaper == "total" && toatalPaperScanLoader) ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "100%", height: "250px" }}
                  >
                    <div className="loader-box">
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-column flex-sm-row  justify-content-center align-items-center mt-5">
                    <div className="d-flex flex-row justify-content-center flex-wrap">
                      <div className="mega-pass-dash-live">
                        <h1>
                          {selectedDmPaper == "total"
                            ? liveTicketpaperSacnnedData?.total_scanned
                            : liveTicketsPaperScanByDm
                            ? liveTicketsPaperScanByDm?.total_scanned
                            : ""}
                        </h1>
                        <p>Total scanned</p>
                      </div>
                      <div
                        className="mega-pass-dash"
                        style={{ background: "#D8AE41" }}
                      >
                        <h1>
                          {selectedDmPaper == "total"
                            ? liveTicketpaperSacnnedData[0]
                            : liveTicketsPaperScanByDm
                            ? liveTicketsPaperScanByDm[0]
                            : ""}
                        </h1>
                        <p>Mega pass</p>
                      </div>
                      <div
                        className="mega-pass-dash"
                        style={{ background: "#FF634C" }}
                      >
                        <h1>
                          {selectedDmPaper == "total"
                            ? liveTicketpaperSacnnedData[1]
                            : liveTicketsPaperScanByDm
                            ? liveTicketsPaperScanByDm[1]
                            : ""}
                        </h1>
                        <p>Adults</p>
                      </div>
                      <div
                        className="mega-pass-dash"
                        style={{ background: "#024DDF" }}
                      >
                        <h1>
                          {selectedDmPaper == "total"
                            ? liveTicketpaperSacnnedData[2]
                            : liveTicketsPaperScanByDm
                            ? liveTicketsPaperScanByDm[2]
                            : ""}
                        </h1>
                        <p>Seniors</p>
                      </div>
                      <div
                        className="mega-pass-dash"
                        style={{ background: "#57C6C2" }}
                      >
                        <h1>
                          {selectedDmPaper == "total"
                            ? liveTicketpaperSacnnedData[3]
                            : liveTicketsPaperScanByDm
                            ? liveTicketsPaperScanByDm[3]
                            : ""}
                        </h1>
                        <p>Children</p>
                        <h3 className="child-desc">(Age 6-12)</h3>
                        {/* <span style={{fontSize:"10px",textAlign:"center"}}>(Age:6-12)</span> */}
                      </div>
                      <div
                        className="mega-pass-dash"
                        style={{ background: "#AD72FF" }}
                      >
                        <h1>
                          {selectedDmPaper == "total"
                            ? liveTicketpaperSacnnedData[4]
                            : liveTicketsPaperScanByDm
                            ? liveTicketsPaperScanByDm[4]
                            : ""}
                        </h1>
                        <p>Children</p>
                        <h3 className="child-desc">(Age 5 & Under)</h3>
                        {/* <span style={{fontSize:"10px",textAlign:"center"}}>(Age:5 & Under)</span> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/*Past data*/}
              <div
                className="ts-box-dm-1 my-2 p-3"
                style={{ background: "white" }}
              >
                <div className="d-flex flex-sm-row flex-column justify-content-between ticket-head-box mt-3">
                  <h4 className="section-header">Past Scanned Data</h4>
                  <div className="d-flex justify-content-around select-div mt-2 mt-sm-0">
                    <CiCalendar size={20} className="me-2" />
                    <select
                      className="select-width text-center"
                      style={{ border: "none" }}
                      onChange={handlePastDateChange}
                    >
                      <option defaultValue value="">
                        Total
                      </option>
                      <option value={"2024-07-25"}>25th July 2024</option>
                      <option value={"2024-07-26"}>26th July 2024</option>
                      <option value={"2024-07-27"}>27th July 2024</option>
                      <option value={"2024-07-28"}>28th July 2024</option>
                    </select>
                  </div>
                </div>

                {pastTicketScanLoader || !pastTicketScan ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "100%", height: "250px" }}
                  >
                    <div className="loader-box">
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center mt-5">
                    <div className="d-flex flex-row justify-content-center flex-wrap">
                      <div className="mega-pass-dash-past">
                        <h1>{pastTicketScan?.total_scan}</h1>
                        <p>Total scanned</p>
                      </div>
                      <div
                        className="mega-pass-past"
                        style={{ border: "1px solid #D8AE41" }}
                      >
                        <h1>{pastTicketScan[0]}</h1>
                        <p>Mega pass</p>
                      </div>
                      <div
                        className="mega-pass-past"
                        style={{ border: "1px solid #FF634C" }}
                      >
                        <h1>{pastTicketScan[1]}</h1>
                        <p>Adults</p>
                      </div>
                      <div
                        className="mega-pass-past"
                        style={{ border: "1px solid #024DDF" }}
                      >
                        <h1>{pastTicketScan[2]}</h1>
                        <p>Seniors</p>
                      </div>
                      <div
                        className="mega-pass-past"
                        style={{ border: "1px solid #57C6C2" }}
                      >
                        <h1>{pastTicketScan[3]}</h1>
                        <p>Children</p>
                        <h3 className="child-desc">(Age 6-12)</h3>
                        {/* <span style={{fontSize:"10px",textAlign:"center"}}>(Age:6-12)</span> */}
                      </div>
                      <div
                        className="mega-pass-past"
                        style={{ border: "1px solid #AD72FF" }}
                      >
                        <h1>{pastTicketScan[4]}</h1>
                        <p>Children</p>
                        <h3 className="child-desc">(Age 5 & Under)</h3>
                        {/* <span style={{fontSize:"10px",textAlign:"center"}}>(Age:5 & Under)</span> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
