import React, { useEffect, useState } from "react";
import "./css/OrgLogin.css";
import img from "../../Assets/login.png";
import eventgemLogo from "../../Assets/eventgemLogo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, signupVerify } from "../../redux/user";
import { toast } from "react-toastify";
import { devApi } from "../../utils/constants";
import axios from "axios";
const regForEmail = RegExp(
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
);

export default function OrgLogin() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logindata, setLogindata] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    credentials: "",
  });
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [unverifiedUser, setUnverifiedUser] = useState();
  const [showVerifyUserLink, setShowVerifyUserLink] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateData = (e) => {
    let { name, value } = e.target;
    setLogindata({
      ...logindata,
      [e.target.name]: e.target.value[0] === " " ? "" : e.target.value,
    });
    switch (name) {
      case "email":
        setErrors({
          ...errors,
          email: value.length === 0 ? "Email is required" : "",
        });
        break;
      case "password":
        setErrors({
          ...errors,
          password: value.length === 0 ? "Password is required" : "",
        });
        break;
      default:
        break;
    }
  };
  const checkError = () => {
    const newErrors = { ...errors };
    let dataIsValid = true;
    if (!logindata.email) {
      dataIsValid = false;
      newErrors.email = "Email is required";
    } else if (!regForEmail.test(logindata.email)) {
      dataIsValid = false;
      newErrors.email = "Invalid email";
    } else {
      newErrors.email = "";
    }
    if (!dataIsValid) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const logins = async (e) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        if (!checkError()) {
          return;
        }
        await axios
          .post(`${devApi}/api/o/authenticateOrganiser`, logindata)
          .then((res) => {
            if (res.data.success) {
              dispatch(
                login({
                  token: res.data.token,
                  user: res.data.user,
                })
              );
              const config = {
                headers: {
                  Authorization: "Bearer " + res.data.token,
                  "Content-type": "application/json",
                  Accept: "application/json",
                  "Cache-Control": "no-cache",
                  Pragma: "no-cache",
                  Expires: "0",
                },
              };

              axios
                .post(`${devApi}/api/o/admin/organiserDashboard`, null, config)
                .then((response) => {
                  if (response.data.success) {
                    navigate(`/dashboard`, { state: response.data.data });
                    toast.success("Login Successful");
                  }
                })
                .catch((error) => {
                  if (error.response.status === 450) {
                    toast.error("Session Expired, Please log-in again");
                    navigate("/organizerlogin");
                  }
                });
            } else {
              setErrors({ ...errors, credentials: res.data.message });
            }
          })
          .catch((error) => {
            if (error.response.status === 451) {
              setUnverifiedUser(error.response.data.user);
              setShowVerifyUserLink(true);
            }
            setErrors({
              ...errors,
              credentials: error.response.data.message,
            });
          });
      } catch (error) {
        setErrors({ ...errors, credentials: error.response.data.message });
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  return (
    <div className="root-container d-flex align-items-center justify-content-center mt-10">
      <div className="logindm-page">
        <Link to="/">
          <img src={eventgemLogo} alt="" />
        </Link>
        <p>Welcome Back! Log in to manage your events.</p>

        <div className="form-box w-100">
          {errors.credentials && (
            <small className={"text-danger ml-2"}>{errors.credentials}</small>
          )}
          {showVerifyUserLink && (
            <Link to="/verifyemail" state={{ ...unverifiedUser }}>
              <small className={"text-primary ms-2"}>Verify Email Now?</small>
            </Link>
          )}
          <div className="mb-2">
            <label htmlFor="" className="label">
              Email
            </label>
            <input
              type="text"
              className="form-input"
              placeholder="Enter Email"
              name="email"
              onChange={updateData}
              value={logindata.email}
            />
            <small className="text-danger ml-2">{errors.email}</small>
          </div>
          <div className="mb-2">
            <label htmlFor="" className="label">
              Password
            </label>
            <div className="d-flex align-items-center signup-input">
              <input
                type={showPassword ? "text" : "password"}
                className="signup-input-password"
                placeholder="Enter Password"
                name="password"
                onChange={updateData}
                value={logindata.password}
              />
              <span
                className="show-hide"
                id="showHide"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <i className="bi bi-eye show-hide"></i>
                ) : (
                  <i
                    className="bi bi-eye-slash show-hide"
                    id="togglePassword"
                  ></i>
                )}
              </span>
            </div>
            <small className="text-danger ml-2">{errors.password}</small>
          </div>
        </div>
        {loading === true ? (
          <div className="d-flex justify-content-center align-items-center">
            <button className="login-btn" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        ) : (
          <button
            className={
              logindata.email === "" || logindata.password === ""
                ? "login-btn-disable"
                : "login-btn"
            }
            onClick={logins}
          >
            Login
          </button>
        )}
      </div>
    </div>
  );
}
